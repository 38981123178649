@import '~@/style/vars.less';

.aspirin-history-tab {
  .ant-tabs-nav-wrap {
    margin-left: 24px;
  }

  .ant-tabs-content .ant-tabs-tabpane {
    padding: 24px 12px 24px 24px;
  }
}

.detail-block {
  height: 100%;
  overflow-y: auto;
}

.overview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  > div {
    width: 100%;
    flex: 1;
    height: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #d6d8d9;
    height: 328px;
    min-height: 328px;
    max-height: 328px;

    + div {
      margin-top: 24px;
    }
  }

  .test-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .item {
      border: 1px solid #ff7e7e;
      padding: 10px 12px;
      border-radius: 4px;
      // flex-basis: 30%;
      flex-basis: calc(~'33.33% - 12px');
      margin: 0 6px;
      margin-bottom: 12px;
    }
  }

  .exam-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .item {
      flex-basis: calc(~'33.33% - 12px');
      margin: 0 6px;
      margin-bottom: 12px;

      .type {
        width: 100%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        background-color: #f1f6ff;
        border-radius: @border-radius;
        color: @primary-color;
        border: 1px solid @primary-color;
      }

      .detail {
        font-size: @font-size-12;
        color: @main-color;
        padding: 0 14px;

        > div {
          margin-top: 4px;
        }
      }
    }
  }

  .exam-block-alone {
    .item {
      width: 100%;
      flex-basis: 100%;
      margin: 0 6px;
      margin-bottom: 12px;

      .type {
        width: 100%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        background-color: #f1f6ff;
        border-radius: @border-radius;
        color: @primary-color;
        border: 1px solid @primary-color;
      }

      .detail {
        font-size: @font-size-12;
        color: @main-color;
        padding: 0 14px;

        > div {
          margin-top: 4px;
        }
      }
    }
  }

  .warning-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .item {
      // flex: 1;
      color: #ff7e7e;
      margin: 0 6px;
      display: flex;
    }
  }

  .other-block {
    overflow-y: auto;
    height: 100%;
    margin-right: 4px;

    > div {
      height: auto !important;
    }
  }
}

@root-entry-name: default;