.dialog-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000000;
  z-index: 99;
}

.dialog-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 2999;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  position: absolute;
  padding: 0 30px 30px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  border: 1px solid #e2e6ed;
  border-radius: 2px;
  min-width: 300px;
}

.dialog-title {
  position: relative;
  border-bottom: 1px solid #e2e6ed;
  margin-bottom: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-title i {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: inline-block;
  background: url(../../assets/images/close.svg);
}

.dialog-option__item {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-option__item div.add-container {
  width: 100%;
  height: 30px;
}

.dialog-option__item div.value-sets div.add {
  float: right;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-top: 7px;
  background-image: url('../../assets/images/page-scale-add.svg');
}

.dialog-option__item div.value-sets div.value-set {
  display: flex;
  height: 36px;
  align-items: center;
}

.dialog-option__item div.value-sets div.value-set span.remove {
  display: inline-block;
  cursor: pointer;
  margin-left: 6px;
  margin-right: 0px;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/images/page-scale-minus.svg');
}

.dialog-option__item div.value-sets div.value-set input {
  width: 125px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #d3d3d3;
  min-height: 30px;
  padding: 5px;
  margin-left: 6px;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  user-select: none;
  font-family: inherit;
}

.dialog-option__item div.value-sets div.value-set input:focus {
  border-color: #4991f2;
}

.dialog-option__item div.source {
  display: flex;
  justify-content: flex-end;
}

.dialog-option__item div.source div.option {
  gap: 8px;
  margin-left: 6px;
  display: flex;
  align-items: center;
}

.dialog-option__item div.data div.file {
  text-align: right;
  flex-flow: column;
  align-items: end;
}

.dialog-option__item div.data div.handwrite {
  flex-flow: column;
}

.dialog-option__item div.data div.file button.file {
  margin: 5px 0px;
}

.dialog-option__item div.data div.file img.preview {
  border: 1px solid #bcbcbc;
}

.dialog-option__item button {
  position: relative;
  display: inline-block;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
  background: #ffffff;
  line-height: 22px;
  padding: 0 16px;
  white-space: nowrap;
  cursor: pointer;
}

.dialog-option__item span {
  margin-right: 12px;
  font-size: 14px;
  color: #3d4757;
  position: relative;
}

.dialog-option__item input,
.dialog-option__item textarea,
.dialog-option__item select {
  width: 276px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #d3d3d3;
  min-height: 30px;
  padding: 5px;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  user-select: none;
  font-family: inherit;
}

.dialog-option__item input[type='checkbox' i] {
  width: 15px;
  appearance: auto;
}

.dialog-option__item input[type='radio' i] {
  width: 15px;
  appearance: auto;
}

.dialog-option__item input[type='file' i] {
  border: none;
}

.dialog-option__item label {
  font-size: 14px;
}

.dialog-option__item input:focus,
.dialog-option__item textarea:focus {
  border-color: #4991f2;
}

.dialog-option__item--require::before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
  position: absolute;
  left: -8px;
}

.dialog-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dialog-menu button {
  position: relative;
  display: inline-block;
  border: 1px solid #e2e6ed;
  border-radius: 2px;
  background: #ffffff;
  line-height: 22px;
  padding: 0 16px;
  white-space: nowrap;
  cursor: pointer;
}

.dialog-menu button:hover {
  background: rgba(25, 55, 88, 0.04);
}

.dialog-menu__cancel {
  margin-right: 16px;
}

.dialog-menu button[type='primary'] {
  color: #ffffff;
  background: #4991f2;
  border-color: #4991f2;
}

.dialog-menu button[type='primary']:hover {
  background: #5b9cf3;
  border-color: #5b9cf3;
}
