.canvas-operation {
  text-align: right;
}

.canvas-operation>div {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: #3d4757;
  user-select: none;
}

.canvas-operation>div:hover {
  color: #6e7175;
}

.canvas-operation>div i {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.canvas-operation__undo {
  background: url(../../assets/images/signature-undo.svg) no-repeat;
}

.canvas-operation__trash {
  background: url(../../assets/images/trash.svg) no-repeat;
}

.canvas-operation>div span {
  font-size: 12px;
  margin: 0 5px;
}

.canvas-canvas {
  user-select: none;
}

.canvas-canvas canvas {
  background: #f3f5f7;
}
