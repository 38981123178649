@import './control/select.css';
@import './date/datePicker.css';
@import './block/block.css';
@import './table/table.css';
@import './resizer/resizer.css';
@import './previewer/previewer.css';
@import './contextmenu/contextmenu.css';
@import './hyperlink/hyperlink.css';

.ce-inputarea {
  width: 0;
  height: 12px;
  min-width: 0;
  min-height: 0;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  letter-spacing: 0;
  font-size: 12px;
  position: absolute;
  outline: none;
  resize: none;
  border: none;
  overflow: hidden;
  color: transparent;
  user-select: none;
  background-color: transparent;
}

.ce-cursor {
  width: 1px;
  height: 20px;
  left: 0;
  right: 0;
  position: absolute;
  outline: none;
  background-color: #000000;
  pointer-events: none;
}

.ce-cursor.ce-cursor--animation {
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: cursorAnimation;
}

@keyframes cursorAnimation {
  from {
    opacity: 1
  }

  13% {
    opacity: 0
  }

  50% {
    opacity: 0
  }

  63% {
    opacity: 1
  }

  to {
    opacity: 1
  }
}