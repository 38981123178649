/* ::-webkit-scrollbar {
  height: 16px;
  width: 16px;
  overflow: visible;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: padding-box;
  border: 4px solid #f2f4f7;
  border-radius: 8px;
  min-height: 24px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c9c9c9;
}

::-webkit-scrollbar-track {
  background: #f2f4f7;
  background-clip: padding-box;
} */

.app {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: transparent;
  position: relative;
  overscroll-behavior: none;
  display: flex;
  justify-content: center;
}

.app-medical ul {
  list-style: none;
  padding-left: 0;
}

.app-medical .menu {
  width: inherit;
  height: 60px;
  top: 0;
  z-index: 3;
  position: sticky;
  display: none;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-shadow: 0 2px 4px 0 transparent;
  background-color: rgba(255, 255, 255);
}

.app-medical .menu-divider {
  width: 1px;
  height: 16px;
  margin: 0 8px;
  display: inline-block;
  background-color: #cfd2d8;
}

.app-medical .menu-item {
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
}

.app-medical .menu-item > div {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
}

.app-medical .menu-item > div:hover {
  background: rgba(25, 55, 88, 0.04);
}

.app-medical .menu-item > div.active {
  background: rgba(25, 55, 88, 0.08);
}

.app-medical .menu-item i {
  width: 16px;
  height: 16px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.app-medical .menu-item > div > span {
  width: 16px;
  height: 3px;
  display: inline-block;
  border: 1px solid #e2e6ed;
}

.app-medical .menu-item .select {
  border: none;
  font-size: 12px;
  line-height: 24px;
  user-select: none;
}

.app-medical .menu-item .select::after {
  position: absolute;
  content: '';
  top: 11px;
  width: 0;
  height: 0;
  right: 2px;
  border-color: #767c85 transparent transparent;
  border-style: solid solid none;
  border-width: 3px 3px 0;
}

.app-medical .menu-item .options {
  width: 70px;
  position: absolute;
  left: 0;
  top: 25px;
  padding: 10px;
  background: #fff;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  border: 1px solid #e2e6ed;
  border-radius: 2px;
  display: none;
}

.app-medical .menu-item .options.visible {
  display: block;
}

.app-medical .menu-item .options li {
  padding: 5px;
  margin: 5px 0;
  user-select: none;
  transition: all 0.3s;
}

.app-medical .menu-item .options li:hover {
  background-color: #ebecef;
}

.app-medical .menu-item .options li.active {
  background-color: #e2e6ed;
}

.app-medical .menu-item .menu-item__font {
  width: 65px;
  position: relative;
}

.app-medical .menu-item__font .select {
  width: 100%;
  height: 100%;
}

.app-medical .menu-item__undo.no-allow,
.app-medical .menu-item__redo.no-allow,
.app-medical .menu-item > div.disable {
  color: #c0c4cc;
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}

.app-medical .menu-item__undo i {
  background-image: url('./assets/images/undo.svg');
}

.app-medical .menu-item__redo i {
  background-image: url('./assets/images/redo.svg');
}

.app-medical .menu-item__painter i {
  background-image: url('./assets/images/painter.svg');
}

.app-medical .menu-item__format i {
  background-image: url('./assets/images/format.svg');
}

.app-medical .menu-item__size-add i {
  background-image: url('./assets/images/size-add.svg');
}

.app-medical .menu-item__size-minus i {
  background-image: url('./assets/images/size-minus.svg');
}

.app-medical .menu-item__bold i {
  background-image: url('./assets/images/bold.svg');
}

.app-medical .menu-item__italic i {
  background-image: url('./assets/images/italic.svg');
}

.app-medical .menu-item__underline i {
  background-image: url('./assets/images/underline.svg');
}

.app-medical .menu-item__strikeout i {
  background-image: url('./assets/images/strikeout.svg');
}

.app-medical .menu-item__superscript i {
  background-image: url('./assets/images/superscript.svg');
}

.app-medical .menu-item__subscript i {
  background-image: url('./assets/images/subscript.svg');
}

.app-medical .menu-item__color,
.app-medical .menu-item__highlight {
  display: flex;
  flex-direction: column;
}

.app-medical .menu-item__color #color,
.app-medical .menu-item__highlight #highlight {
  width: 1px;
  height: 1px;
  visibility: hidden;
  outline: none;
  appearance: none;
}

.app-medical .menu-item__color i {
  background-image: url('./assets/images/color.svg');
}

.app-medical .menu-item__color span {
  background-color: #000000;
}

.app-medical .menu-item__highlight i {
  background-image: url('./assets/images/highlight.svg');
}

.app-medical .menu-item__highlight span {
  background-color: #ffff00;
}

.app-medical .menu-item__left i {
  background-image: url('./assets/images/left.svg');
}

.app-medical .menu-item__center i {
  background-image: url('./assets/images/center.svg');
}

.app-medical .menu-item__right i {
  background-image: url('./assets/images/right.svg');
}

.app-medical .menu-item__alignment i {
  background-image: url('./assets/images/alignment.svg');
}

.app-medical .menu-item__row-margin {
  position: relative;
}

.app-medical .menu-item__row-margin i {
  background-image: url('./assets/images/row-margin.svg');
}

.app-medical .menu-item__image i {
  background-image: url('./assets/images/image.svg');
}

.app-medical .menu-item__image input {
  display: none;
}

.app-medical .menu-item__signature i {
  background-image: url('./assets/images/signature.svg');
}

.app-medical .menu-item__table {
  position: relative;
}

.app-medical .menu-item__table i {
  background-image: url('./assets/images/table.svg');
}

.app-medical .menu-item .menu-item__table__collapse {
  width: 270px;
  height: 310px;
  background: #fff;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  border: 1px solid #e2e6ed;
  box-sizing: border-box;
  border-radius: 2px;
  position: absolute;
  display: none;
  z-index: 99;
  top: 25px;
  left: 0;
  padding: 14px 27px;
  cursor: auto;
}

.app-medical .menu-item .menu-item__table__collapse .table-close {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.app-medical .menu-item .menu-item__table__collapse .table-close:hover {
  color: #7d7e80;
}

.app-medical .menu-item .menu-item__table__collapse:hover {
  background: #fff;
}

.app-medical .menu-item .menu-item__table__collapse .table-title {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
  border-bottom: 1px solid #e2e6ed;
}

.app-medical .table-title span {
  font-size: 12px;
  color: #3d4757;
  display: inline;
  margin: 0;
}

.app-medical .table-panel {
  cursor: pointer;
}

.app-medical .table-panel .table-row {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 10px;
  pointer-events: none;
}

.app-medical .table-panel .table-cel {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border: 1px solid #e2e6ed;
  background: #fff;
  position: relative;
  margin-right: 6px;
  pointer-events: none;
}

.app-medical .table-panel .table-cel.active {
  border: 1px solid rgba(73, 145, 242, 0.2);
  background: rgba(73, 145, 242, 0.15);
}

.app-medical .table-panel .table-row .table-cel:last-child {
  margin-right: 0;
}

.app-medical .menu-item__hyperlink i {
  background-image: url('./assets/images/hyperlink.svg');
}

.app-medical .menu-item__separator {
  position: relative;
}

.app-medical .menu-item__separator > i {
  background-image: url('./assets/images/separator.svg');
}

.app-medical .menu-item .menu-item__separator .options {
  width: 128px;
}

.app-medical .menu-item .menu-item__separator li {
  padding: 1px 5px;
}

.app-medical .menu-item__separator li i {
  pointer-events: none;
}

.app-medical .menu-item__separator li[data-separator='0,0'] {
  background-image: url('./assets/images/line-single.svg');
}

.app-medical .menu-item__separator li[data-separator='1,1'] {
  background-image: url('./assets/images/line-dot.svg');
}

.app-medical .menu-item__separator li[data-separator='3,1'] {
  background-image: url('./assets/images/line-dash-small-gap.svg');
}

.app-medical .menu-item__separator li[data-separator='4,4'] {
  background-image: url('./assets/images/line-dash-large-gap.svg');
}

.app-medical .menu-item__separator li[data-separator='7,3,3,3'] {
  background-image: url('./assets/images/line-dash-dot.svg');
}

.app-medical .menu-item__separator li[data-separator='6,2,2,2,2,2'] {
  background-image: url('./assets/images/line-dash-dot-dot.svg');
}

.app-medical .menu-item__watermark > i {
  background-image: url('./assets/images/watermark.svg');
}

.app-medical .menu-item__watermark {
  position: relative;
}

.app-medical .menu-item__page-break i {
  background-image: url('./assets/images/page-break.svg');
}

.app-medical .menu-item__control {
  position: relative;
}

.app-medical .menu-item__control i {
  background-image: url('./assets/images/control.svg');
}

.app-medical .menu-item__checkbox i {
  background-image: url('./assets/images/checkbox.svg');
}

.app-medical .menu-item__latex i {
  background-image: url('./assets/images/latex.svg');
}

.app-medical .menu-item__block i {
  background-image: url('./assets/images/block.svg');
}

.app-medical .menu-item .menu-item__control .options {
  width: 80px;
}

.app-medical .menu-item__search {
  position: relative;
}

.app-medical .menu-item__search i {
  background-image: url('./assets/images/search.svg');
}

.app-medical .menu-item .menu-item__search__collapse {
  width: 260px;
  height: 72px;
  box-sizing: border-box;
  position: absolute;
  display: none;
  z-index: 99;
  top: 25px;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 5px 5px #e3dfdf;
}

.app-medical .menu-item .menu-item__search__collapse:hover {
  background: #ffffff;
}

.app-medical .menu-item .menu-item__search__collapse > div {
  width: 250px;
  height: 36px;
  padding: 0 5px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}

.app-medical .menu-item .menu-item__search__collapse > div input {
  width: 205px;
  height: 27px;
  appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  line-height: 27px;
  outline: none;
  padding: 0 5px;
}

.app-medical .menu-item .menu-item__search__collapse > div span {
  height: 100%;
  color: #dcdfe6;
  font-size: 25px;
  display: inline-block;
  border: 0;
  padding: 0 10px;
}

.app-medical .menu-item .menu-item__search__collapse__replace button {
  display: inline-block;
  border: 1px solid #e2e6ed;
  border-radius: 2px;
  background: #fff;
  line-height: 22px;
  padding: 0 6px;
  white-space: nowrap;
  margin-left: 4px;
  cursor: pointer;
  font-size: 12px;
}

.app-medical .menu-item .menu-item__search__collapse__replace button:hover {
  background: rgba(25, 55, 88, 0.04);
}

.app-medical .menu-item .menu-item__search__collapse__search {
  position: relative;
}

.app-medical .menu-item .menu-item__search__collapse__search label {
  right: 110px;
  font-size: 12px;
  color: #3d4757;
  position: absolute;
}

.app-medical .menu-item .menu-item__search__collapse__search > input {
  padding: 5px 90px 5px 5px !important;
}

.app-medical .menu-item .menu-item__search__collapse__search > div {
  width: 28px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-left: 1px solid #e2e6ed;
  transition: all 0.5s;
}

.app-medical .menu-item .menu-item__search__collapse__search > div:hover {
  background-color: rgba(25, 55, 88, 0.04);
}

.app-medical .menu-item .menu-item__search__collapse__search i {
  width: 6px;
  height: 8px;
  transform: translateY(1px);
}

.app-medical .menu-item .menu-item__search__collapse__search .arrow-left {
  right: 76px;
}

.app-medical .menu-item .menu-item__search__collapse__search .arrow-left i {
  background: url(./assets/images/arrow-left.svg) no-repeat;
}

.app-medical .menu-item .menu-item__search__collapse__search .arrow-right {
  right: 48px;
}

.app-medical .menu-item .menu-item__search__collapse__search .arrow-right i {
  background: url(./assets/images/arrow-right.svg) no-repeat;
}

.app-medical .menu-item__print i {
  background-image: url('./assets/images/print.svg');
}

.app-medical .menu-item__export i {
  background-image: url('./assets/images/export.svg');
}

.app-medical .menu-item__formdata i {
  background-image: url('./assets/images/formdata.svg');
}

.app-medical .editor > div {
  /* margin: 80px auto; */
}

.app-medical .ce-page-container canvas {
  /* box-shadow: rgb(158 161 165 / 40%) 0px 2px 12px 0px; */
}

.app-medical .footer {
  width: inherit;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  z-index: 9;
  position: sticky;
  bottom: 0;
  left: 0;
  font-size: 12px;
  padding: 0 4px 0 20px;
  box-sizing: border-box;
}

.app-medical .footer > div:first-child {
  display: flex;
  align-items: center;
}

.app-medical .footer .page-mode {
  padding: 1px;
  position: relative;
}

.app-medical .footer .page-mode i {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  cursor: pointer;
  display: inline-block;
  background-image: url('./assets/images/page-mode.svg');
}

.app-medical .footer .options {
  width: 70px;
  position: absolute;
  left: 0;
  bottom: 25px;
  padding: 10px;
  background: #fff;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  border: 1px solid #e2e6ed;
  border-radius: 2px;
  display: none;
}

.app-medical .footer .options.visible {
  display: block;
}

.app-medical .footer .options li {
  padding: 5px;
  margin: 5px 0;
  user-select: none;
  transition: all 0.3s;
  text-align: center;
  cursor: pointer;
}

.app-medical .footer .options li:hover {
  background-color: #ebecef;
}

.app-medical .footer .options li.active {
  background-color: #e2e6ed;
}

.app-medical .footer > div:first-child > span {
  display: inline-block;
  margin-right: 5px;
  letter-spacing: 1px;
}

.app-medical .footer > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-medical .footer > div:last-child > div {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-medical .footer > div:last-child > div:hover {
  background: rgba(25, 55, 88, 0.04);
}

.app-medical .footer > div:last-child i {
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
}

.app-medical .footer .page-scale-minus i {
  background-image: url('./assets/images/page-scale-minus.svg');
}

.app-medical .footer .page-scale-add i {
  background-image: url('./assets/images/page-scale-add.svg');
}

.app-medical .footer .page-scale-percentage {
  cursor: pointer;
  user-select: none;
}

.app-medical .footer .fullscreen i {
  background-image: url('./assets/images/request-fullscreen.svg');
}

.app-medical .footer .fullscreen.exist i {
  background-image: url('./assets/images/exit-fullscreen.svg');
}

.app-medical .footer .paper-margin i {
  background-image: url('./assets/images/paper-margin.svg');
}

.app-medical .footer .editor-mode {
  cursor: pointer;
  user-select: none;
}

.app-medical .footer .paper-size {
  position: relative;
}

.app-medical .footer .paper-size i {
  background-image: url('./assets/images/paper-size.svg');
}

.app-medical .footer .paper-size .options {
  right: 0;
  left: unset;
}

.app-medical .footer .paper-direction {
  position: relative;
}

.app-medical .footer .paper-direction i {
  background-image: url('./assets/images/paper-direction.svg');
}

.app-medical .footer .paper-direction .options {
  right: 0;
  left: unset;
}

.app-medical .ce-contextmenu-formdata {
  background-image: url('./assets/images/formdata.svg');
}

.app-medical .toast {
  visibility: hidden;
  background-color: #333;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  font-size: 17px;
  line-height: 40px;
  z-index: 100;
  min-width: 100px;
  position: fixed;
  left: 50%;
  top: 50%;
  height: 40px;
  transform: translate(-100%, -50%);
}

.app-medical .toast.visible {
  visibility: visible;
  -webkit-animation:
    fadein 0.5s,
    fadeout 0.5s 1.5s;
  animation:
    fadein 0.5s,
    fadeout 0.5s 1.5s;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

#cdss-tips {
  position: absolute;
  top: 732px;
  left: 588px;
  width: 40px;
  height: 40px;
  background: url('./assets/cdss.png');
  background-size: cover;
  cursor: pointer;
  z-index: 10;
}
