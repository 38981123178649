.cdss-order-container {
  width: 1246px;
  height: 680px;
  .ant-popover-title {
    padding: 0 !important;
  }

  .ant-tabs-nav-wrap {
    padding: 0 24px;
  }

  .ant-popover-inner-content {
    padding: 0;
    margin: 0;
  }
}

.cdss-order-content-container {
  .main {
    width: 100%;
    height: 630px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    > .left {
      width: 327px;
      min-width: 327px;
      height: 100%;
      flex: 0;
      padding: 20px 22px;
      border-right: 1px solid #d6d8d9;
      position: relative;

      .item {
        height: 48px;
        font-size: 16px;
        color: #5d6268;
        padding: 0 24px;
        line-height: 48px;
        cursor: pointer;
      }

      .active-item {
        color: #3276e8;
        background-color: #f1f6ff;
      }
    }

    > .right {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 24px 16px 0 16px;
      background-color: #f9fafc;

      .info {
        flex: 1;
        overflow-y: auto;

        .aspirin-block {
          height: auto !important;
        }

        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          height: 54px;
          border-bottom: 1px solid #d6d8d9;
          padding: 0 20px;
          cursor: pointer;

          > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .type {
            flex: 0;
            white-space: nowrap;
            margin: 0 38px;
            font-size: 15px;
            color: #3276e8;
          }

          .label {
            white-space: nowrap;
            font-size: 15px;
            color: #303133;
          }

          .desc {
            margin-left: 120px;
            font-size: 12px;
            color: #5d6268;
            overflow: hidden;
            height: 100%;
            padding: 4px 0;
            text-overflow: ellipsis;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* 指定要显示的行数 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 300px;
            max-width: 300px;
            min-width: 300px;
          }
        }
      }

      .footer {
        flex: 0;
        width: 100%;
        min-height: 48px;
        height: 48px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ant-btn {
          + .ant-btn {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

@root-entry-name: default;