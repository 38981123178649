@import '~@/style/vars.less';

.@{prefix}-center-mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 1400px;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.5);

  .aspirin-table {
    .ant-pro-card {
      background-color: transparent !important;
    }
    .ant-spin-container {
      background-color: transparent !important;
    }
    .ant-table {
      background-color: transparent;
    }

    .ant-table-placeholder {
      &:hover {
        > td {
          background-color: transparent !important;
        }
      }
    }

    .ant-table-thead .ant-table-cell {
      background-color: #dbedff;
      &::before {
        background-color: transparent !important;
      }
    }
  }

  .extra-operation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > div {
      margin-left: 24px;
      color: @primary-color;
      cursor: pointer;
      display: flex;
      align-items: center;

      > span {
        margin-left: 4px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > div {
      width: 100%;
    }

    .first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .desc {
        color: #aeb0b3;
        font-size: @font-size;
      }

      .oper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ant-btn {
          margin-left: 6px;
          border-width: 0;
        }
      }
    }

    .second {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      padding-top: 8px;

      &::before {
        position: absolute;
        content: '';
        width: calc(~'100% + 32px');
        height: 1px;
        background-color: @primary-color;
        top: 0;
        left: -16px;
      }

      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-left: 12px;
      }

      .label {
        color: #5d6268;
        font-size: @font-size-12;

        &::after {
          content: ':';
        }
      }

      .value {
        margin-left: 12px;
        color: @primary-color;
        font-size: @font-size;
      }
    }
  }
}

.normal-mainContainer {
  min-width: 0 !important;
}

.cpoe-test {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  // > div {
  //   height: 100%;
  // }

  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;

    .info {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .label {
        color: #5d6268;
        font-size: 12px;
        margin-right: 12px;

        &::after {
          content: ':';
        }
      }

      .value {
        font-size: 16px;
        color: @primary-color;
      }
    }

    .ant-btn {
      border-width: 0;
      font-size: 14px;

      + .ant-btn {
        margin-left: 12px;
      }
    }

    .cancel {
      background-color: #f0f0f0;
      color: #5d6268;
    }
  }

  > .main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    height: 0;

    > .left {
      width: 648px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px;
      background-color: #f9fafc;

      .ant-input {
        flex: 0;
      }

      .list {
        max-height: 100%;
        overflow-y: auto;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 24px;

        .item {
          flex-basis: 50%;
          height: 48px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #d6d8d9;
          border-right: 1px solid #d6d8d9;
          padding: 0 14px 0 24px;
          cursor: pointer;

          > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            > div {
              + div {
                margin-left: 10px;
              }
            }
          }

          &:first-of-type {
            border-top: 1px solid #d6d8d9;
            border-left: 1px solid #d6d8d9;
          }

          &:nth-of-type(2) {
            border-top: 1px solid #d6d8d9;
          }

          &:nth-child(odd) {
            border-left: 1px solid #d6d8d9;
          }
        }
      }
    }

    > .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      height: 100%;

      > div {
        height: 100%;
      }

      .first {
        width: 300px;
        min-width: 300px;
        flex: 0;
        border-right: 1px solid #d6d8d9;

        .clear-all {
          color: #3276e8;
          font-size: 16px;
          cursor: pointer;

          > span {
            margin-left: 2px;
          }
        }

        .aspirin-block {
          .header {
            padding: 24px 8px 0 24px;
          }
        }

        .check-list {
          .item {
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px 0 24px;
            cursor: pointer;
            user-select: none;

            .oper {
              cursor: pointer;
            }

            .label {
              color: #343b42;
              font-size: 16px;
            }
            .value {
              color: #aeb0b3;
              font-size: 14px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
          }
        }
      }

      .second {
        flex: 1;

        .aspirin-block {
          .header {
            padding: 24px 8px 0 24px;
          }
        }

        .info-form {
          padding: 0 24px;
        }

        .textarea {
          border: 1px solid #d9d9d9;
        }
      }
    }
  }
}

.cpoe-exam {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  // > div {
  //   height: 100%;
  // }

  > .footer {
    height: 48px;
    min-height: 48px;
    width: 100%;
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    padding: 0 24px;

    .info {
      margin-right: 24px;
      display: flex;
      align-items: center;

      .label {
        color: #5d6268;
        font-size: 12px;
        margin-right: 12px;

        &::after {
          content: ':';
        }
      }

      .value {
        font-size: 16px;
        color: @primary-color;
      }
    }

    .ant-btn {
      border-width: 0;
      font-size: 14px;

      + .ant-btn {
        margin-left: 12px;
      }
    }

    .cancel {
      background-color: #f0f0f0;
      color: #5d6268;
    }
  }

  > .main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    width: 100%;
    height: 0;

    > .left {
      width: 773px !important;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f9fafc;

      > .header {
        padding: 24px 12px 12px 24px;
      }

      .ant-input {
        flex: 0;
      }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-self: center;
        height: 100%;

        .first {
          width: 200px;
          max-width: 200px;
          min-width: 200px;
          height: 100%;
          overflow-y: auto;
          background-color: #fff;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
            color: @primary-color;

            .label {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .second {
          width: 260px;
          max-width: 260px;
          min-width: 260px;
          height: 100%;
          border-right: 1px solid #d6d8d9;
          overflow-y: auto;
          background-color: #f9fafc;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
            }
          }

          .active-item {
            background-color: #f1f6ff;
            color: @primary-color;

            .label {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .third {
          flex: 1;
          height: 100%;
          overflow-y: auto;
          background-color: #f9fafc;

          .item {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
            cursor: pointer;

            .label {
              color: #5d6268;
              font-size: 16px;
              margin-left: 8px;
            }
          }

          .right {
            > span {
              + span {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }

    > .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      height: 100%;
      padding: 24px;

      > div {
        height: 100%;
      }

      .first {
        flex: 1;

        .clear-all {
          color: #3276e8;
          font-size: 16px;
          cursor: pointer;

          > span {
            margin-left: 2px;
          }
        }

        .check-list {
          padding: 24px;
          background-color: #f9fafc;

          > .ant-tag {
            margin-bottom: 4px;
          }
        }
      }

      // .second {
      //   flex: 1;

      //   .aspirin-block {
      //     .header {
      //       padding: 24px 8px 0 24px;
      //     }
      //   }

      //   .info-form {
      //     padding: 0 24px;
      //   }

      //   .textarea {
      //     border: 1px solid #d9d9d9;
      //   }
      // }
    }
  }
}

.aspirin-table-advice {
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: transparent;
  }
  tbody {
    // td.ant-table-cell {
    //   .ant-space-item {
    //     &:first-of-type {
    //       display: none;
    //     }
    //   }
    // }

    .ant-table-cell-fix-right,
    .ant-table-cell-fix-left {
      background-color: rgb(239, 246, 252) !important;
    }
  }
}

.order-main-color {
  color: #3276e8 !important;
  > div {
    > span {
      color: #3276e8 !important;
    }
  }
}

.order-status-color {
  color: #30a313 !important;
  > div {
    > span {
      color: #30a313 !important;
    }
  }
}

@root-entry-name: default;