.product_records_wapper {

    .ant-modal-header {
      border: none;
    }
  
    .custom_title {
      width: 100%;
      height: 70px;
  
      background: linear-gradient(180deg, #D2DBFF 0%, #FFFFFF 89%);
      border-radius: 4px 4px 4px 4px;
      position: relative;
      overflow: hidden;
  
      .leftradio {
        // width: 55px;
        // height: 55px;
        position: absolute;
        left: -37px;
        top: -43px;
      }
  
      .cdssheader {
        width: 114px;
        height: 49px;
        position: absolute;
        top: 14px;
        right: 140px;
      }
  
      .robot {
        width: 48px;
        height: 48px;
        position: absolute;
        top: -13px;
        right: 110px;
      }
  
      .label {
        font-size: 20px;
        font-weight: bold;
        color: #3276E8;
  
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 24px;
        z-index: 2;
      }
  
    }
  
    .ant-modal-header {
      padding: 0;
    }
  }
@root-entry-name: default;