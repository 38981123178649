@import '~@/style/vars.less';

@font-face {
  font-family: 'DINPro';
  src: url('../assets/fonts/DINPro.ttf') format('truetype');
  /* 可以添加其他字体格式（如.woff或.woff2）的src声明 */
}

@font-face {
  font-family: 'DINPro-Bold';
  src: url('../assets/fonts/DINPro-Bold.ttf') format('truetype');
  /* 可以添加其他字体格式（如.woff或.woff2）的src声明 */
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url('../assets/fonts/DINPro-Medium.woff') format('truetype');
  /* 可以添加其他字体格式（如.woff或.woff2）的src声明 */
}

body {
  color: @main-color;
}

.hideScroll {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.dinpro {
  font-family:
    'DINPro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  /* 设置默认字体为DINPro，如果无法加载则使用系统默认的sans-serif字体 */
}

.dinpro-bold {
  font-family:
    'DINPro-Bold',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  /* 设置默认字体为DINPro，如果无法加载则使用系统默认的sans-serif字体 */
}

.dinpro-medium {
  font-family:
    'DINPro-Medium',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  /* 设置默认字体为DINPro，如果无法加载则使用系统默认的sans-serif字体 */
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.noscroll {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

.vs-page {
  padding: 0 !important;
}

.aspirin-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > button,
  > div {
    margin-left: 24px;
  }
}

.aspirin-btn {
  color: @main-color !important;
  padding: 6px 24px !important;
  height: 36px !important;
}

.aspirin-btn-primary {
  color: @white !important;
  background-color: @primary-color !important;
  padding: 6px 24px !important;
  height: 36px !important;
}

.aspirin-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 100%;
  }

  > .ant-pro-card {
    flex: 1;
    height: 0;

    > .ant-pro-card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      > div {
        width: 100%;
      }

      .ant-table-wrapper {
        flex: 1;
        height: 0;

        .ant-spin-nested-loading {
          height: 100%;
        }

        .ant-spin-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
        }

        .ant-table {
          width: 100%;
        }

        .ant-pagination {
          width: 100%;
          margin: 16px 0 0 0 !important;
        }
      }
    }
  }

  .ant-pro-table-search {
    margin-bottom: @margin !important;
    flex: 0;
    height: auto;

    .ant-form-item {
      margin-bottom: 8px;
    }
  }

  .ant-pro-table-list-toolbar {
    .ant-pro-table-list-toolbar-title {
      position: relative;
      text-indent: 12px;
      font-weight: @font-weight-bold;
      font-size: @font-size-20;

      &::before {
        position: absolute;
        content: '';
        background-color: @primary-color;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    .vs-table-addtion {
      font-size: @font-size-14;
    }
  }
}

.aspirin-table-nopadding {
  .ant-pro-card {
    > .ant-pro-card-body {
      padding: 0 !important;
    }
  }
}

.aspirin-table-transparent {
  background-color: transparent;

  > .ant-pro-card,
  .ant-table,
  .ant-table-cell,
  .ant-table-cell-row-hover {
    background-color: transparent !important;
  }

  .ant-table-cell {
    border-color: #aeb0b3;
  }
}

.aspirin-child-table {
  &:only-child {
    .ant-table {
      padding-left: 35px;
    }
  }
}

.aspirin-linkpage {
  .ant-modal-title {
    color: @primary-color;
  }

  .ant-modal-footer {
    .ant-btn-text {
      background-color: #f0f0f0;
    }
  }
}

.aspirin-linkpage-static-height {
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      width: 100%;
    }

    .ant-modal-body {
      flex: 1;
      height: 0;
    }
  }
}

.aspirin-tab {
  height: 100%;

  .ant-tabs-content {
    height: 100%;

    .ant-tabs-tabpane {
      height: 100%;
    }
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }
}

.aspirin-transfer {
  height: calc(~'100vh - 500px');

  .ant-transfer-list-body {
    overflow-y: auto;
  }
}

.aspirin-drawer {
  .ant-drawer-header {
    padding: 0 14px;
    min-height: 47px;
  }
  .ant-drawer-title {
    width: 0;
  }

  .ant-drawer-body {
    padding: 0;
  }
}

.aspirin-block-title {
  font-size: @font-size-20;
  font-weight: @font-weight-bold;
  color: @title-color;
  text-indent: 12px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    background-color: #3276e8;
    width: 4px;
    height: 70%;
    top: 15%;
    left: 0;
  }
}

.aspirin-table-search {
  position: relative;

  > div.ant-pro-table-search {
    padding: 30px 24px;

    > form {
      > .ant-row-start {
        position: relative;
        bottom: -50px;

        > .ant-col {
          .ant-form-item-label {
            display: flex;

            > label {
              &::after {
                content: '';
              }
            }
          }

          &:last-child {
            position: relative;
            right: 0;
            top: -132px;
            display: flex;
            max-width: 100%;
            margin: 0;
            flex: 1;
            // padding: 0 !important;
            padding-left: 0 !important;

            > div {
              width: 100%;
            }

            .ant-space,
            .ant-space-item {
              width: 100%;
            }

            .ant-pro-table-search-item-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }

            .ant-pro-table-search-button-box {
              position: relative;
              right: 0;
              display: flex;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}

.aspirin-list {
  width: 100%;

  .aspirin-list-title {
    margin-bottom: 12px;
  }

  .aspirin-list-item {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    color: @main-color;
    font-size: @font-size-18;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .aspirin-list-item-active {
    background-color: #f0f0f0;
  }
}

.cdss-image-robot {
  width: 30px !important;
  cursor: pointer;
}

.aspirin-reproduction-form {
  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
  }
}

@root-entry-name: default;