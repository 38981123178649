@import '~@/style/vars.less';

.@{prefix}-header-main {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    color: #fff;

    .logo {
      // width: 50px;
      height: 50px;
      float: left;
    }

    .title {
      margin-left: 10px;
      font-size: 24px;
      font-style: normal;
      // font-weight: 700;
    }

    .detail {
      font-size: 18px;
      margin-left: 4px;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 24px;
    color: #fff;
    font-size: @font-size;

    .small-search {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-input {
        background-color: rgba(255, 255, 255, 0.2);
        border-width: 0 !important;
        color: #fff;

        &:focus {
          border-color: transparent;
          box-shadow: none;
        }
      }

      .ant-input-group-addon {
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        border-width: 0 !important;
      }
    }

    .small-search-before {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .label {
        margin-left: 4px;
      }

      .vp-icon {
        top: -2px;
        position: relative;
      }
    }

    > div {
      + div {
        margin-left: 28px;
      }
    }

    .dropdown-container {
      .anticon {
        margin-left: 4px;
      }
    }

    .department {
      font-weight: bold;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      .ant-image {
        border-radius: 50%;
      }

      .ant-image-img {
        border-radius: 50%;
      }
    }

    .header-icon {
      cursor: pointer;
    }
  }
}

@root-entry-name: default;