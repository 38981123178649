.transplant_record_text{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        color: #343B42;
}
.transplant_record_import{
    width: 88px;
    height: 20px;
    border-radius: 2px;
    opacity: 1;
    border: 1px solid #3276E8;
    font-weight: 400;
    color: #3276E8;
    line-height: 14px;
    text-align: center;
    line-height: 18px;
    margin-top: 5px;
    cursor: pointer;
}
@root-entry-name: default;