.cpoe-reproduction {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  > .header {
    width: 100%;
    height: 100px;
    flex: 0;
    min-height: 100px;

    .medical-extra {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0;
      margin-bottom: 12px;
      margin-left: 20px;

      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .item {
        cursor: pointer;
        + .item {
          margin-left: 20px;
        }

        .label {
          margin-left: 4px;
        }
      }

      .active-item {
        color: #3276e8;
      }
    }

    .toggle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #3276e8;
      padding: 0 0 0 20px;
      position: relative;

      &::before {
        content: '';
        width: 16px;
        height: 1px;
        background-color: #3276e8;
        position: absolute;
        left: -16px;
        bottom: -1px;
      }

      &::after {
        content: '';
        width: 16px;
        height: 1px;
        background-color: #3276e8;
        position: absolute;
        right: -16px;
        bottom: -1px;
      }

      .medical-dropdown {
        height: 34px;
        color: #3276e8;
        font-size: 18px;
      }
    }
  }

  > .main {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.cpoe-reproduction-template {
  background-color: #fff;
  width: 158px;
  max-height: calc(~'100vh - 500px');
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 0;
  overflow-y: auto;

  .item {
    height: 52px;
    width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .sign {
      width: 12px;
      height: 12px;
      background-color: #5d6268;
      border-radius: 50%;
      margin: 0 12px;
    }

    .word {
      font-size: 18px;
    }
  }

  .active-item {
    cursor: pointer;
    background-color: #f1f6ff;
    color: #3276e8;

    .sign {
      background-color: #3276e8;
    }
  }

  .site-tree-search-value {
    color: #f50;
  }
}
.toggle_right {
  color: #3276e8;
  font-size: 14px;
  display: flex;
  &_item {
    margin-left: 10px;
    cursor: pointer;
  }
}

@root-entry-name: default;