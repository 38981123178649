.aspirin-block {
  // padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  > div {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .aspirin-block-title {
      font-weight: 500;
      font-size: 20px;
      color: #343b42;
      white-space: nowrap;
      position: relative;
      text-indent: 20px;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 5px;
        height: 70%;
        background-color: #3276e8;
        position: absolute;
        top: 15%;
        left: 0;
      }
    }

    .aspirin-block-desc {
      font-size: 16px;
      color: #343b42;
      font-weight: 400;
      text-indent: 20px;
      // margin-left: 24px;
    }
  }

  .aspirin-block-main {
    flex: 1;
    height: 0;
  }

  .aspirin-block-footer {
    flex: 0;
  }
}

.aspirin-block-nomargin {
  margin-bottom: 0;
}

@root-entry-name: default;