@import '~@/style/vars.less';

.@{layout} {
  height: 100vh;
  display: flex;
  flex-direction: column;

  // .@{layout}-sider {
  //   background: #fff;
  //   box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.05);
  // }

  .@{layout}-header {
    height: 68px;
    min-height: 68px;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #010e33 0%, #005184 100%);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25);
    flex: 0;
  }

  .@{layout}-sub-header {
    height: 64px;
    min-height: 64px;
    max-width: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #010e33e6 0%, #005184e6 100%);
    flex: 0;
    padding: 0 24px 0 32px;
    overflow: hidden;

    .@{layout}-index-header-menu {
      overflow-y: hidden;
      overflow-x: auto;
      flex: 1;
      margin-right: 64px;
      user-select: none;
    }

    .ant-menu-horizontal {
      background-color: transparent;
      border-bottom: 0;

      .ant-menu-item,
      .ant-menu-submenu {
        padding: 0 !important;
        margin: 0 48px;
        color: @white;
        font-size: @font-size-18;

        &::after {
          left: 0;
          right: 0;
        }

        &:hover {
          color: @white;
          &::after {
            border-width: 4px;
            border-color: linear-gradient(270deg, #0bb6ff 0%, #0063f1 100%);
          }
        }
      }

      .ant-menu-submenu-title {
        &:hover {
          color: @white;
        }
      }

      .ant-menu-item-selected,
      .ant-menu-submenu-selected {
        &::after {
          border-width: 4px;
          border-color: linear-gradient(270deg, #0bb6ff 0%, #0063f1 100%);
        }
      }
    }
  }

  .@{layout}-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .@{layout}-sider {
      background: linear-gradient(180deg, #010e33 0%, #005184 100%);
    }
  }

  .@{layout}-main {
    flex: 1;
    // padding: @padding;
    overflow: hidden;
    background: linear-gradient(180deg, #e1f1ff 0%, #dce1e4 100%);
    display: flex;
  }
}

.sider-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #343b42;
    font-size: @font-size-14;
    margin-top: 24px;
    cursor: pointer;
    width: 116px;
    padding: 24px 0;
    position: relative;

    &:hover {
      .bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(270deg, #19baff 0%, #0e203a 100%);
        z-index: 1;
        opacity: 0.5;
      }
    }

    > span {
      color: #86898d;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      position: relative;

      + span {
        margin-left: 9px;
        margin-top: 0;
        white-space: nowrap;
        font-size: @font-size-18;
        display: flex;
        justify-content: center;
        align-self: center;
      }
    }

    .label {
      width: 72px;
      transition: all 0.2s ease-in-out;
      opacity: 1;
      color: @grey-1;
      margin-top: 4px;
    }
  }

  .active-item {
    color: #fff;
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(270deg, #19baff 0%, #0e203a 100%);
      z-index: 1;
      opacity: 0.5;
    }

    .icon {
      color: @white;
      position: relative;
      z-index: 2;
    }

    .label {
      color: @white;
      position: relative;
      z-index: 2;
    }
  }
}

@root-entry-name: default;