.cdss-container {
  .ant-popover-title {
    overflow: hidden;
    padding: 0;
    border-bottom: none;
    height: 70px;
    font-family:
      PingFang SC-Medium,
      PingFang SC;
    font-size: 20px;
    // color: #3276E8;
    font-weight: 500 !important;
    background: linear-gradient(180deg, #d2e7ff 0%, #ffffff 100%);
  }

  .ant-popover-content {
    position: relative;
    // width: 303px;
  }

  .ant-popover-inner-content {
    padding: 24px;
    width: 100%;
  }

  .ant-popover-inner {
    background: #ffffff;
    box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    opacity: 1;
  }

  .ant-popover-inner-content {
    padding: 0px;
    width: 100%;
  }

  /* 去掉按钮的 ::after 伪元素效果 */
  .ant-btn::after {
    display: none !important;
  }

  .header-icon {
    display: flex;
    justify-content: space-between;
    position: relative;

    .leftradio {
      overflow: hidden;
      position: absolute;
      width: 116px;
      height: 116px;
      left: -68px;
      top: -68px;

      img {
        width: 116px;
        height: 116px;
      }
    }

    .top-title {
      position: absolute;
      top: 21px;
      left: 24px;
      font-size: 20px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500;
      color: #3276e8;
    }

    .del-hander {
      position: absolute;
      width: 114px;
      height: 49px;
      // bottom: 6px;
      top: 15px;
      right: 28px;

      img {
        width: 114px;
        height: 49px;
      }
    }

    .robot {
      width: 48px;
      height: 48px;
      position: absolute;
      top: -12px;
      right: 66px;
    }

    .delete-icon {
      width: 20px;
      height: 20px;
      right: 12px;
      top: 12px;
      position: absolute;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.cdss-content-container {
  .assistant {
    display: flex;
    margin-bottom: 12px;

    .title {
      font-size: 16px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #343b42;
      margin-bottom: 10px;
    }

    .remark-tooltop {
      font-size: 16px;
      font-family:
        PingFang SC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #3276e8;
    }

    .titleMedical {
      white-space: nowrap;
      font-size: 16px;
      font-family:
        PingFang SC-Regular,
        PingFang SC;
      font-weight: 500;
      color: #343b42;
    }

    .remark {
      font-size: 16px;
      font-family:
        PingFang SC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #5d6268;
    }

    .content {
      .content-title {
        font-size: 16px;
        font-family:
          PingFang SC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #343b42;
      }

      .content-remark {
        font-size: 16px;
        font-family:
          PingFang SC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #5d6268;

        margin-top: 8px;
      }
    }
  }

  .proofreading {
    display: flex;

    .proofreading-title {
      font-size: 16px;
      white-space: nowrap;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #343b42;
      // line-height: 14px;
      margin-bottom: 10px;
    }

    .proofreading-age {
      width: 256px;
      font-size: 16px;
      font-family:
        PingFang SC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #5d6268;
    }

    .proofreading-tooltop {
      font-size: 16px;
      font-weight: 400;
      color: #5d6268;
    }
  }

  .diagnosis {
    text-align: left;
    display: flex;
    padding: 24px;

    .list {
      padding-left: 0;

      > div {
        position: relative;
        width: 250px;

        .mare-icon {
          position: absolute;
          width: 18px;
          height: 18px;
          display: inline-block;
          text-align: center;
          margin-right: 17px;
          margin-left: 40px;
          right: 1px;

          img {
            width: 15px;
            height: 15px;
            margin-bottom: 1px;
          }
        }
      }
    }

    .diagnosis-content {
      margin-left: 7px;
      width: 240px;
      font-size: 12px;
      font-weight: 400;
      color: #5d6268;
      height: 190px;
      min-height: 190px;
    }
  }

  .verify {
    padding: 24px;
    padding-top: 7px;
    max-width: 300px;

    .title {
      // width: 112px;
      height: 14px;
      font-size: 16px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #343b42;
      line-height: 14px;
    }
  }

  .advice-verify {
    padding: 24px;
    padding-top: 0px;

    .advice-title {
      font-size: 16px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #3276e8;
    }

    .advice-titleMedical {
      height: 16px;
      font-size: 16px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #343b42;
      line-height: 16px;
      margin-bottom: 12px;
    }

    .advice-tooltop {
      font-size: 16px;
      font-family:
        PingFang SC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #5d6268;
    }

    .advice-title {
      height: 14px;
      font-size: 16px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #343b42;
      // line-height: 14px;
      margin-bottom: 10px;
    }
  }

  .del-hander {
    margin-right: 3px;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .voice {
    width: 454px;
    height: 587px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .voice-item {
      padding-left: 14px;
      display: flex;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #d6d8d9;
      // line-height: 42px;
      margin-bottom: 12px;
      font-size: 16px;
      font-family:
        PingFang SC-Medium,
        PingFang SC;
      font-weight: 500 !important;
      color: #343b42;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .voice-content {
      width: 289px;
      font-size: 16px;
      font-family:
        PingFang SC-Regular,
        PingFang SC;
      font-weight: 400;
      color: #5d6268;
      line-height: 26px;
    }

    .voice-foot {
      width: 453px;
      height: 101px;
      line-height: 101px;
      margin-bottom: 0px;
      position: relative;
      border-top: 1px solid #d6d8d9;
      justify-content: space-between;
      align-items: center;

      .cancel {
        .cancel-icon {
          position: absolute;
          width: 35px;
          height: 35px;
          margin-left: 51px;
          top: 50%;
          transform: translateY(-50%);

          img {
            width: 35px;
            height: 35px;
            top: 50%;
            transform: translateY(-100%);
          }
        }

        .cancel-line {
          position: absolute;
          width: 66px;
          height: 25px;
          margin-left: 116px;
          top: 50%;
          transform: translateY(-100%);

          img {
            width: 66px;
            height: 25px;
            top: 50%;
            transform: translateY(-100%);
          }
        }
      }

      .center {
        position: absolute;
        width: 66px;
        height: 66px;
        margin-left: 194px;

        img {
          width: 66px;
          height: 66px;
          top: 50%;
          // transform: translateY(-100%);
        }
      }

      .cancellast {
        .cancel-icon {
          position: absolute;
          width: 35px;
          height: 35px;
          margin-left: 376px;
          top: 50%;
          transform: translateY(-50%);

          img {
            width: 35px;
            height: 35px;
            top: 50%;
            transform: translateY(-100%);
          }
        }

        .cancel-line {
          position: absolute;
          width: 66px;
          height: 25px;
          margin-left: 272px;
          top: 50%;
          transform: translateY(-100%);

          img {
            width: 66px;
            height: 25px;
            top: 50%;
            transform: translateY(-100%);
          }
        }
      }
    }
  }

  .voice-content-m {
    padding: 24px;
    padding-top: 21px;
  }

  .advice-foot {
    margin-top: 24px;
    text-align: right;
  }

  .custom-button {
    width: 80px;
    height: 30px;
    background: #f0f0f0;
    border-radius: 2px 2px 2px 2px;
  }

  .custom-button-enter {
    width: 80px;
    height: 30px;
  }

  .box {
    position: relative;
    /* 为了定位伪元素 */
    padding-left: 10px;
    /* 为了给竖线留出空间 */
    font-size: 20px;
    font-family:
      PingFang SC-Medium,
      PingFang SC;
    font-weight: 500 !important;
    color: #343b42;
    width: 850px;
  }

  .box::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 4px;
    height: 20px;
    line-height: 20px;
    background: #3276e8;
    /* 蓝色竖线的颜色 */
  }

  .ant-picker-range {
    width: 263px;
    height: 32px;
  }

  .echars {
    width: 646px;
    height: 288px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-left: 77px;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 1px 1px 10px -5px rgba(0, 0, 0, 0.25);
    margin-top: 24px;
    margin-bottom: 36px;
  }

  .newTitle {
    font-size: 16px;
    font-family:
      PingFang SC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #343b42;
  }

  // .text-remark {
  //   display: flex;
  //   margin-top: 20px;
  //   margin-bottom: 12px;

  //   .text-remark-title {
  //     width: 80px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Medium,
  //       PingFang SC;
  //     font-weight: 500;
  //     color: #343b42;
  //     line-height: 16px;
  //   }

  //   .text-remark-count {
  //     width: 57px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Regular,
  //       PingFang SC;
  //     font-weight: 400;
  //     color: #5d6268;
  //     line-height: 16px;
  //     margin-left: 44px;
  //   }
  // }

  // .text-remark-low {
  //   margin-bottom: 12px;
  //   display: flex;

  //   .text-remark-title {
  //     width: 112px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Medium,
  //       PingFang SC;
  //     font-weight: 500;
  //     color: #343b42;
  //     line-height: 16px;
  //   }

  //   .text-remark-count {
  //     width: 57px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Regular,
  //       PingFang SC;
  //     font-weight: 400;
  //     color: #5d6268;
  //     line-height: 16px;
  //     margin-left: 12px;
  //   }
  // }

  // .text-remark-tail {
  //   margin-bottom: 12px;
  //   display: flex;

  //   .text-remark-title {
  //     width: 112px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Medium,
  //       PingFang SC;
  //     font-weight: 500;
  //     color: #343b42;
  //     line-height: 16px;
  //   }

  //   .text-remark-count {
  //     width: 57px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Regular,
  //       PingFang SC;
  //     font-weight: 400;
  //     color: #5d6268;
  //     line-height: 16px;
  //     margin-left: 12px;
  //   }
  // }

  // .text-remark-current {
  //   margin-bottom: 12px;
  //   display: flex;

  //   .text-remark-title {
  //     width: 53px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Medium,
  //       PingFang SC;
  //     font-weight: 500;
  //     color: #343b42;
  //     line-height: 16px;
  //   }

  //   .text-remark-count {
  //     width: 57px;
  //     height: 16px;
  //     font-size: 16px;
  //     font-family:
  //       PingFang SC-Regular,
  //       PingFang SC;
  //     font-weight: 400;
  //     color: #5d6268;
  //     line-height: 16px;
  //     margin-left: 71px;
  //   }
  // }

  .text-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 20px;

    + .text-item {
      margin-top: 12px;
    }

    .text-item-title {
      flex: 1;
      max-width: 150px;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 500;
      color: #343b42;
    }
    .text-item-value {
      flex: 1;
      white-space: pre-wrap;
      font-size: 16px;
      color: #5d6268;
    }
  }

  .text-item-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;

    + .text-item-2 {
      margin-top: 12px;
    }

    .text-item-title-2 {
      flex: 1;
      max-width: 150px;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #343b42;
    }
    .text-item-value-2 {
      flex: 1;
      white-space: pre-wrap;
      font-size: 16px;
      text-indent: 20px;
      margin-top: 12px;
      color: #5d6268;
    }
  }
  .result {
    margin-top: 36px;
    margin-bottom: 24px;
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family:
      PingFang SC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #343b42;
    line-height: 18px;
  }

  .ltfv {
    font-size: 16px;
    font-family:
      PingFang SC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #5d6268;
  }

  .choose {
    height: 120px;
    font-size: 16px;
    font-family:
      PingFang SC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #5d6268;
    line-height: 24px;
    text-align: left;

    ul {
      // margin-right: px;
      margin-left: -20px;
    }
  }

  .suggestion {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: 18px;
    font-family:
      PingFang SC-Medium,
      PingFang SC;
    font-weight: 500;
    color: #343b42;
  }

  .suggestion-text {
    font-size: 16px;
    font-family:
      PingFang SC-Regular,
      PingFang SC;
    font-weight: 400;
    color: #5d6268;
    margin-bottom: 30px;
  }

  #chart-container {
    border: 1px solid #ccc;
    /* 设置边框样式，可以根据需要自定义颜色和宽度 */
    padding: 10px;
    /* 可选：添加一些内边距以分隔边框和图表 */
  }

  .medical {
    display: flex;
    height: calc(~'100vh - 300px');
    max-height: 1088px;
    width: 850px;

    .medical-content {
      width: 160px;
      border-right: 1px solid #d6d8d9;
      // flex: 1;
      margin-right: 16px;
      // overflow-y: auto;
      .medical-title {
        margin-top: 2px;
        margin-bottom: 30px;
        margin-left: 29px;
        width: 48px;
        height: 24px;
        font-size: 24px;
        font-family:
          PingFang SC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #343b42;
        line-height: 24px;
      }

      .medical-steps {
        height: calc(100% - 60px);
        overflow-y: auto;
        li {
          position: relative;
          padding-top: 24px;
          padding-bottom: 24px;
          border-left: 1px solid #d6d8d9;

          ::before {
            content: '';
            /* 伪元素的内容为空 */
            width: 10px;
            /* 圆的直径为 10px */
            height: 10px;
            /* 圆的直径为 10px */
            background-color: #d6d8d9;
            /* 圆的背景颜色 */
            border-radius: 50%;
            /* 将伪元素变成圆形 */
            position: absolute;
            /* 绝对定位，相对于父盒子定位 */
            left: -5px;
            /* 左侧定位为 0，即紧贴在父盒子的前面 */
            top: 50%-5px;
            /* 垂直居中，可以根据需要调整 */
            transform: translateY(-50%);
            /* 垂直居中，基于父盒子的高度 */
          }

          a {
            font-size: 16px;
            font-family:
              PingFang SC-Medium,
              PingFang SC;
            font-weight: 500;
            color: #5d6268;
            padding-left: 10px;
          }
        }
      }
    }

    .medical-right {
      // width: 603px;
      flex: 1;
      overflow: scroll;
      margin-right: 16px;

      .medical-right-title {
        height: 32px;
        font-size: 32px;
        font-family:
          PingFang SC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #343b42;
        line-height: 32px;
        margin-top: 2px;
        margin-left: 30px;
      }

      .medical-right-remark {
        font-size: 16px;
        font-family:
          PingFang SC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #5d6268;

        margin-top: 24px;
        // margin-bottom: 32px;
        margin-left: 30px;
      }

      .medical-right-name {
        // width: 40px;
        height: 20px;
        font-size: 20px;
        font-family:
          PingFang SC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #343b42;
        line-height: 20px;
        position: relative;
        display: inline-block;
        margin-left: 46px;
        margin-top: 32px;
      }

      .medical-right-name::before {
        content: '';
        position: absolute;
        left: -12px;
        width: 4px;
        height: 20px;
        background: #3276e8;
        line-height: 20px;
        /* 蓝色竖线的颜色 */
      }

      .medical-right-name::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        width: 900px;
        height: 1px;
        background-color: #d6d8d9;
        /* 横线颜色 */
        transform: translateY(-50%);
        margin-left: 10px;
      }

      .medical-right-chine-name {
        height: 20px;
        font-size: 20px;
        font-family:
          PingFang SC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #343b42;
        line-height: 20px;
        margin-top: 24px;
        margin-bottom: 16px;
        margin-left: 46px;
      }

      .medical-right-name-remark {
        font-size: 16px;
        font-family:
          PingFang SC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #5d6268;

        margin-top: 16px;
        margin-left: 46px;
        margin-bottom: 24px;
      }

      .medical-right-name-category {
        margin-top: 24px;
        height: 20px;
        font-size: 20px;
        font-family:
          PingFang SC-Medium,
          PingFang SC;
        font-weight: 500;
        color: #343b42;
        line-height: 20px;
        margin-left: 46px;
      }

      .medical-right-name-category-remark {
        font-size: 16px;
        font-family:
          PingFang SC-Regular,
          PingFang SC;
        font-weight: 400;
        color: #5d6268;

        margin-left: 46px;
        margin-top: 16px;
        margin-bottom: 60px;
      }
    }
  }

  .ant-steps-item-title {
    height: 16px;
    font-size: 16px !important;
    font-family:
      PingFang SC-Medium,
      PingFang SC;
    font-weight: 500;
    // color: #3276E8 !important;
    line-height: 16px;
  }

  /* 完成的步骤标题样式 */
  .ant-steps-item-active {
    .ant-steps-item-title {
      color: #3276e8 !important;
      /* 完成后标题颜色 */
    }
  }

  /* 清除步骤条中每个步骤的上边距和下边距 */
  .ant-steps-item {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /* 清除步骤条中每个步骤的内边距 */
  .ant-steps-item-container {
    padding: 0 !important;
  }

  .ant-steps-vertical .ant-steps-item:not(:last-child) {
    margin-bottom: 42px;
    /* 修改步骤之间的垂直距离 */
  }

  .leftradio-diagnosis {
    overflow: hidden;
    position: absolute;
    width: 116px;
    height: 116px;
    left: -42px;
    top: -60px;

    img {
      width: 116px;
      height: 116px;
    }
  }
}
.selected {
  color: #5d6268 !important;
}
.unselect {
  color: #3276e8 !important;
}

@root-entry-name: default;