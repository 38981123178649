.signature-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: #000000;
  z-index: 99;
}

.signature-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 999;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signature {
  position: absolute;
  padding: 0 30px 30px;
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  border: 1px solid #e2e6ed;
  border-radius: 2px;
}

.signature-title {
  position: relative;
  border-bottom: 1px solid #e2e6ed;
  margin-bottom: 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signature-title i {
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: inline-block;
  background: url(../../assets/images/close.svg);
}

.signature-menu {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.signature-menu button {
  position: relative;
  display: inline-block;
  border: 1px solid #e2e6ed;
  border-radius: 2px;
  background: #ffffff;
  line-height: 22px;
  padding: 0 16px;
  white-space: nowrap;
  cursor: pointer;
}

.signature-menu button:hover {
  background: rgba(25, 55, 88, .04);
}

.signature-menu__cancel {
  margin-right: 16px;
}

.signature-menu button[type='primary'] {
  color: #ffffff;
  background: #4991f2;
  border-color: #4991f2;
}

.signature-menu button[type='primary']:hover {
  background: #5b9cf3;
  border-color: #5b9cf3;
}