.ce-hyperlink-popup {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgb(98 107 132 / 20%);
  border-radius: 2px;
  color: #3d4757;
  padding: 12px 16px;
  position: absolute;
  z-index: 1;
  text-align: center;
  display: none;
}

.ce-hyperlink-popup a {
  min-width: 100px;
  max-width: 300px;
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: #0000ff;
}