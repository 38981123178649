.examine_detail{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 0;
    &_header{
        position: sticky;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &_item{
            margin-bottom: 20px;
            box-sizing: border-box;
            width: 33%;
            display: flex;
            font-size: 14px;
            color: #5D6268;
         &_label{
              width: 80px;
              margin-right: 12px;
              box-sizing: border-box;
              color: #85898D;
              flex-shrink: 0;
         }
    }
    }
    &_tabel{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        width: 100%;
        &_promise_table{
            .ant-table-thead .ant-table-cell {
                background-color: #DBEDFF;
                font-size: 16px;
                font-weight: 600;
                &::before {
                  background-color: transparent !important;
                }
        }
        td.ant-table-cell {
            background-color: transparent;
          }
  
          .ant-pro-card {
            background-color: transparent;
          }
  
          .ant-table {
            background-color: transparent !important;
          }
    }
}
}
@root-entry-name: default;