@lineLeft: 22px;

.aspirin-timeline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      height: calc(~'50% + 8px');
      top: -8px;
      left: @lineLeft;
      border-right: 1px dashed #aeb0b3;
    }

    &::after {
      content: '';
      position: absolute;
      height: calc(~'50% + 8px');
      top: calc(~'50% + 8px');
      left: @lineLeft;
      border-right: 1px dashed #aeb0b3;
    }

    &:first-of-type {
      &::before {
        content: '';
        position: absolute;
        height: 0;
        border-right-width: 0;
      }

      &::after {
        content: '';
        position: absolute;
        height: calc(~'50% + 8px');
        top: calc(~'50% + 8px');
        left: @lineLeft;
        border-right: 1px dashed #aeb0b3;
      }
    }

    &:last-of-type {
      &::before {
        content: '';
        position: absolute;
        height: calc(~'50% + 8px');
        top: -8px;
        left: @lineLeft;
        border-right: 1px dashed #aeb0b3;
      }

      &::after {
        content: '';
        position: absolute;
        height: 0;
        border-right-width: 0;
      }
    }

    + .item {
      margin-top: 16px;
    }

    .sign {
      margin: 0 16px;
      width: 12px;
      height: 12px;
      background-color: #aeb0b3;
      border-radius: 50%;
      z-index: 9;
    }
  }

  .active-item {
    &::before,
    &::after {
      border-right-color: #3276e8 !important;
    }

    .sign {
      background-color: #3276e8;
    }
  }

  .active-half-item {
    &::before {
      border-right-color: #3276e8 !important;
    }
  }

  .only-item {
    &::before,
    &::after {
      content: '';
      border-right-width: 0 !important;
    }
  }

  .content {
    flex: 1;
  }
}

@root-entry-name: default;