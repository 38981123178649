.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../assets/login/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;

  .login-image-2 {
    background-image: url('../../assets/login/bg-2.png');
    // background-repeat: no-repeat;
    background-size: contain;
    // background-position: right;
    height: 617px;
    width: 617px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .login-block {
    user-select: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 757px;
    padding: 0 142px;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .title {
      font-size: 48px;
      font-weight: bold;
      color: #3276e8;
      margin-bottom: 98px;
      position: relative;
      z-index: 9;

      &::after {
        content: '';
        width: 192px;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: -12px;
        background-color: #3276e8;
      }
    }

    .login-form {
      width: 100%;
      z-index: 9;

      .ant-input-affix-wrapper {
        border-width: 0;
        background: #eef0f5;
        border-radius: 42px;
        height: 64px;
        line-height: 64px;
        padding: 0 21px;

        input {
          background: #eef0f5;
          font-size: 24px;
          font-weight: 400;
          text-indent: 12px;
          // color: #aeb0b3;
        }
      }

      .medical-type-choose {
        border-width: 0;
        background: #eef0f5;
        border-radius: 42px;
        height: 64px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 21px;
        width: 250px;
        margin-bottom: 24px;
        cursor: pointer;

        > span {
          background: #eef0f5;
          font-size: 20px;
          font-weight: 400;
        }

        .word {
          margin: 0 16px;
        }
      }

      .extra-oper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        font-size: 20px;

        .ant-checkbox-wrapper {
          font-size: 20px !important;
        }

        .ant-btn {
          font-size: 20px;
        }
      }

      .login-form-button {
        border-radius: 59.625px;
        background: linear-gradient(90deg, #4aacf5 0%, #2354dd 100%);
        box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15);
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 0;
        font-size: 28px;
        font-weight: 500;
      }
    }
  }

  .other-method {
    margin: 24px 0 75px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 16px;
    font-weight: 400;

    > span {
      color: #85898d;
    }

    .ant-btn {
      color: #3276e8;
    }
  }

  .login-divider {
    > span {
      position: relative;
      top: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #aeb0b3;
    }
  }

  .other-way-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .anticon {
      font-size: 24px;
      margin: 0 2px;
      cursor: pointer;
    }
  }
}

.login-item-popover {
  // background-color: #fff;
  width: 200px;
  // box-shadow:
  //   0 6px 16px 0 rgba(0, 0, 0, 0.08),
  //   0 3px 6px -4px rgba(0, 0, 0, 0.12),
  //   0 9px 28px 8px rgba(0, 0, 0, 0.05);
  // border-radius: 4px;

  .ant-popover-inner-content {
    width: 100%;
  }

  .item {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    // width: 220px;
  }
}

@root-entry-name: default;