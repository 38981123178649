.ce-date-container {
  display: none;
  width: 300px;
  overflow: hidden;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: #606266;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px;
  user-select: none;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.ce-date-container.active {
  display: block;
}

.ce-date-wrap {
  display: none;
}

.ce-date-wrap.active {
  display: block;
}

.ce-date-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #606266;
  font-size: 16px;
}

.ce-date-title>span {
  display: inline-block;
}

.ce-date-title>span:not(.ce-date-title__now) {
  font-family: cursive;
  cursor: pointer;
}

.ce-date-title>span:not(.ce-date-title__now):hover {
  color: #5175F4;
}

.ce-date-title .ce-date-title__pre-year {
  width: 15%;
}

.ce-date-title .ce-date-title__pre-month {
  width: 15%;
}

.ce-date-title .ce-date-title__now {
  width: 40%;
}

.ce-date-title .ce-date-title__next-year {
  width: 15%;
}

.ce-date-title .ce-date-title__next-month {
  width: 15%;
}

.ce-date-week {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e4e7ed;
}

.ce-date-week>span {
  list-style: none;
  width: calc(100%/7);
  text-align: center;
  color: #606266;
  font-size: 14px;
}

.ce-date-day {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
}

.ce-date-day>div {
  width: calc(100%/7);
  height: 40px;
  text-align: center;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
  line-height: 40px;
  border-radius: 4px;
}

.ce-date-day>div:hover {
  color: #5175F4;
  opacity: .8;
}

.ce-date-day>div.active {
  color: #5175F4;
  font-weight: 700;
}

.ce-date-day>div.disable {
  color: #c0c4cc;
}

.ce-date-day>div.select {
  color: #fff;
  background-color: #5175F4;
}

.ce-time-wrap {
  display: none;
  padding: 10px;
  height: 286px;
}

.ce-time-wrap ::-webkit-scrollbar {
  width: 0;
}

.ce-time-wrap.active {
  display: flex;
}

.ce-time-wrap li {
  list-style: none;
}

.ce-time-wrap>li {
  width: 33.3%;
  height: 100%;
  text-align: center;
}

.ce-time-wrap>li>span {
  transform: translateY(-5px);
  display: inline-block;
}

.ce-time-wrap>li>ol {
  height: calc(100% - 20px);
  overflow-y: auto;
  border: 1px solid #e2e2e2;
  position: relative;
}

.ce-time-wrap>li:first-child>ol {
  border-right: 0;
}

.ce-time-wrap>li:last-child>ol {
  border-left: 0;
}

.ce-time-wrap>li>ol>li {
  line-height: 30px;
  cursor: pointer;
  transition: all .3s;
}

.ce-time-wrap>li>ol>li:hover {
  background-color: #eaeaea;
}

.ce-time-wrap>li>ol>li.active {
  color: #ffffff;
  background: #5175F4;
}

.ce-date-menu {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  position: relative;
  border-top: 1px solid #e4e7ed;
}

.ce-date-menu button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  user-select: none;
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 10px;
}

.ce-date-menu button:hover {
  color: #5175F4;
  border-color: #5175F4;
}

.ce-date-menu button.ce-date-menu__time {
  border: 1px solid transparent;
  position: absolute;
  left: 0;
  margin-left: 0;
}

.ce-date-menu button.ce-date-menu__time:hover {
  color: #5175F4;
}