@import '~@/style/vars.less';

.cpoe-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  overflow: auto;
  background: linear-gradient(180deg, #add6f7 0%, #dce1e4 100%);

  > .left {
    width: 950px;
    min-width: 950px;
    height: 100%;
    margin-right: 16px;

    .left-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      > .header {
        height: 156px;
        min-height: 156px;
        width: 100%;
        background: url('../../assets/login-bg-1.png');
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 12px;

        > .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .avatar {
          width: 100px;
          height: 100px;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #e4e4e4;
          margin: 0 24px;
        }

        .info {
          color: @primary-color;
          .first {
            font-size: 40px;
          }
          .second {
            font-size: 24px;
          }
        }

        .extra {
          display: flex;
          align-items: center;

          .item {
            width: 65px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            + .item {
              margin-left: 24px;
            }
          }

          .ant-image {
            width: 36px;
            height: 36px;
          }

          .word {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            color: @title-color;
            margin-top: 10px;
            white-space: nowrap;

            .label {
              margin-right: 4px;
              &::after {
                content: ':';
              }
            }
          }
        }
      }

      > .main {
        height: 458px;
        min-height: 458px;
        width: 100%;
        margin-top: 16px;
        border-radius: 4px;
        position: relative;
        padding: 24px;

        .first {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          z-index: 2;
          position: relative;
          height: 176px;
          padding: 0 10px 0 16px;

          &::before {
            content: '';
            width: 4px;
            height: 20px;
            background-color: @primary-color;
            position: absolute;
            top: 7px;
            left: 0;
          }

          > div {
            flex-basis: 33%;
            flex: 1;
            // padding: 0 16px;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: center;

            .label {
              font-size: 20px;
              color: #343b42;
              &::after {
                content: ':';
                margin: 0 6px 0 4px;
              }
            }

            .value {
              color: @primary-color;
              font-size: 20px;
            }
          }
        }

        .second {
          width: 100%;
          height: 330px;
          position: relative;
          z-index: 2;
        }

        .aspirin-block {
          position: relative;
          z-index: 2;
        }

        .first-in {
          display: flex;
          justify-content: space-between;
          position: relative;
          z-index: 2;

          .item {
            + .item {
              margin-top: 8px;
            }
            .label {
              font-size: 16px;
              color: #343b42;
              &::after {
                content: ':';
                margin: 0 6px 0 4px;
              }
            }

            .value {
              color: @primary-color;
              font-size: 16px;
            }
          }
        }

        .second-in {
          display: flex;
          flex-wrap: wrap;

          .item-left {
            flex: 0;
            flex-basis: 70%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            // margin-right: 16px;
            white-space: nowrap;
          }

          .item-right {
            flex: 1;
          }

          .item {
            flex: 1;
            flex-basis: calc(~'50% - 8px');
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 36px;
            position: relative;
            border-radius: 2px;
            margin-bottom: 16px;

            &:nth-child(odd) {
              margin-right: 12px;
            }

            > div {
              position: relative;
              z-index: 2;
            }

            .bg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #fff;
              opacity: 0.5;
              z-index: 1;
              border-radius: 2px;
            }

            .avatar {
              margin: 0 16px 0 12px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              color: #e4e4e4;
            }

            .number {
              font-size: 16px;
              color: #3276e8;
              margin-right: 16px;
            }

            .name {
              font-size: 14px;
              color: #343b42;
            }

            .gender {
              font-size: 14px;
              color: #5d6268;
              margin: 0 16px;
            }

            .age {
              color: #5d6268;
            }
          }
        }
      }

      > .footer {
        padding: 24px;
        position: relative;
        border-radius: 4px;
        margin-top: 16px;
        width: 100%;
        min-height: 630px;
        max-height: 630px;
        height: 630px;

        .aspirin-block {
          position: relative;
          z-index: 2;
        }

        .extra-oper {
          white-space: nowrap;

          .ant-radio-group ant-radio-group-outline {
            white-space: nowrap;
          }
        }

        th.ant-table-cell {
          background-color: #e4eeff;
        }
        td.ant-table-cell {
          background-color: transparent;
        }

        .ant-pro-card {
          background-color: transparent;
        }

        .ant-table {
          background-color: transparent !important;
        }
      }
    }
  }

  > .center {
    height: 100%;
    margin-right: 16px;

    .center-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 1502px;

      > .first {
        height: 630px;
        min-height: 630px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 24px 0 24px 24px;
        position: relative;

        > .left {
          width: 940px;
          height: 100%;
          margin-right: 24px;
          z-index: 99;
        }

        > .right {
          flex: 1;
          z-index: 99;
          padding-right: 10px;

          .extra-oper {
            color: #85898d;
            font-size: 14px;
          }

          .aspirin-block-main {
            padding-right: 14px;
          }

          .right-item {
            height: 74px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            border-bottom: 1px solid #aeb0b3;
          }

          .info {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .index {
              margin: 0 24px 0 24px;
              font-size: 20px;
              color: #ff7e7e;
            }

            .main {
              font-size: 16px;
              .first {
                color: #ff7e7e;
              }
              .second {
                color: #85898d;
                font-size: 14px;
                margin-top: 8px;
              }
            }
          }

          .oper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .first {
              color: #3276e8;
              font-size: 12px;
              margin-bottom: 8px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .second {
              color: #85898d;
              font-size: 14px;
            }
          }
        }
      }

      > .second {
        flex: 0;
        position: relative;
        margin-top: 16px;
        padding: 24px;
        height: 630px;
        max-height: 630px;
        min-height: 630px;
        display: flex;

        > .right {
          width: 471px;
          min-width: 471px;
          z-index: 99;
          height: 100%;
          position: relative;

          .right-item {
            height: 72px;
            min-height: 72px;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            margin-bottom: 16px;
            padding: 0 16px;

            > .left {
              display: flex;
              flex-direction: column;

              > .label {
                margin-bottom: 8px;
              }
            }

            > .right {
              display: flex;
              flex-direction: column;

              .oper {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .vp-icon {
                  margin-bottom: 8px;
                  cursor: pointer;
                  + .vp-icon {
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }

        > .left {
          z-index: 99;
          position: relative;
          margin-right: 36px;

          .aspirin-block-main {
            display: flex;
            align-items: flex-end;
          }
        }
      }
    }
  }

  > .right {
    height: 100%;
    position: relative;

    .right-container {
      display: flex;
      flex-direction: column;
      height: 1276px;
      min-height: 1276px;
      max-height: 1276px;
      width: 808px;
      padding: 16px;

      .login-ab-bg {
        height: 1276px;
      }

      .aspirin-block {
        position: relative;
        z-index: 99;

        .aspirin-block-main {
          display: flex;
          flex-direction: column;
        }
      }

      .title {
        font-weight: 500;
        color: #3276e8;
        font-size: 24px;
        height: 54px;
        min-height: 54px;
        line-height: 54px;
        flex: 0;
        width: 100%;
        background: url('../../assets/bg2.png');
        background-size: cover;
        text-align: center;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
      }

      .main-list {
        margin: 16px 0;
        flex: 1;
        height: 0;
        overflow-y: auto;

        .item {
          height: 76px;
          background-color: #fff;
          border: 2px solid transparent;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .ant-checkbox-wrapper {
              margin: 0 16px;
            }

            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .label {
                font-size: 18px;
                color: #343b42;
              }

              .type {
                color: #5d6268;
                font-size: 14px;
              }
            }
          }

          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 28px;
            width: 56px;
            height: 28px;
            background: rgba(50, 118, 232, 0.1);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            font-size: 18px;
            color: #3276e8;
          }

          + .item {
            margin-top: 16px;
          }
        }

        .active-item {
          border-color: #3276e8;
        }
      }

      .extra-order-type {
        .label {
          margin-right: 20px;
          color: #343b42;
          font-size: 16px;
        }
      }

      .main-list-in {
        margin: 16px 0;
        flex: 1;
        height: 0;
        overflow-y: auto;

        .item {
          height: 118px;
          background-color: #fff;
          border-left: 4px solid transparent;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 0 24px 0 12px;

          + .item {
            margin-top: 16px;
          }

          .left {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1;

            .type {
              color: #343b42;
              font-size: 18px;
              margin-bottom: 6px;
              font-weight: bold;
              text-indent: 20px;
              position: relative;

              &::before {
                content: '';
                width: 8px;
                height: 8px;
                background-color: #ff7e7e;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: calc(~'50% - 4px');
              }
            }

            .label {
              font-size: 16px;
              color: #343b42;
              margin-bottom: 12px;
              font-weight: bold;
            }

            .extra {
              font-size: 16px;
              color: #85898d;
              display: flex;

              .name {
                padding-right: 16px;
                margin-right: 16px;
                position: relative;

                &::after {
                  content: '';
                  width: 1px;
                  height: 60%;
                  right: 0;
                  top: 20%;
                  position: absolute;
                  background-color: #85898d;
                }
              }
            }
          }

          .right {
            position: relative;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            width: 120px;
            max-width: 120px;
            min-width: 120px;
            flex: 0;
            display: flex;
            padding: 16px 0;

            .more {
              font-size: 14px;
              color: #85898d;
            }

            .oper {
              display: flex;
              align-items: center;

              .status {
                color: #343b42;
                font-size: 18px;
                font-weight: bold;
                position: relative;

                &::before {
                  content: '';
                  width: 6px;
                  height: 6px;
                  background-color: #ff7e7e;
                  border-radius: 50%;
                  position: absolute;
                  left: -20px;
                  top: calc(~'50% - 3px');
                }
              }

              .icon {
                width: 24px;
                height: 24px;
                background-color: #3276e8;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                margin-left: 12px;
              }
            }
          }
        }
      }

      .submit-oper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .choose {
          margin-bottom: 24px;
        }
        .btn {
          height: 76px;
          background: linear-gradient(90deg, #014d7e 0%, #0071bd 100%);
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          text-align: center;
          line-height: 76px;
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

.login-ab-bg {
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  z-index: 1;
}

@root-entry-name: default;