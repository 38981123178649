@import '~@/style/vars.less';

.@{prefix}-center-leftContainer-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1;
}

.@{prefix}-center-leftContainer {
  width: 1240px;
  min-width: 1240px;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  height: calc(~'100% + 24px');
  position: relative;
  margin-top: -24px;
  position: relative;
  overflow: hidden;

  > .main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 16px;
    border-radius: @border-radius;
    border: 4px solid @primary-color;
    border-top-color: transparent;
    z-index: 22;
  }

  .aspirin-index-block {
    + .aspirin-index-block {
      margin-top: @margin;
    }
  }

  .tab-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;

    .tab-line {
      position: absolute;
      width: 100%;
      height: 30px;
      border: 4px solid @primary-color;
      border-bottom-width: 0;
      border-top-width: 0;
      border-radius: @border-radius;
      bottom: -6px;

      // &::before {
      //   content: '';
      //   background-color: @primary-color;
      //   width: 350px;
      //   height: 4px;
      //   position: absolute;
      //   left: -1px;
      //   top: 0;
      // }

      // &::after {
      //   content: '';
      //   background-color: @primary-color;
      //   width: 350px;
      //   height: 4px;
      //   position: absolute;
      //   right: -1px;
      //   top: 0;
      // }
    }

    // .tab-line-in {
    //   &::before {
    //     width: 250px;
    //   }

    //   &::after {
    //     width: 250px;
    //   }
    // }

    // .tab-line-out {
    //   &::before {
    //     width: 300px;
    //   }

    //   &::after {
    //     width: 300px;
    //   }
    // }

    // &::before {
    //   content: '';
    //   background-color: @primary-color;
    //   width: 50%;
    //   height: 4px;
    //   position: absolute;
    //   left: -1px;
    //   bottom: -4px;
    // }

    .tab-icon-container {
      display: flex;
      flex-direction: row;
      position: relative;
      // top: 24px;

      > .tab-icon-block {
        position: relative;

        &:first-of-type {
          &::before {
            content: '';
            background-color: #3276e8;
            width: 3500px;
            height: 4px;
            position: absolute;
            right: 24px;
            top: 22px;
            z-index: -1;
          }
        }

        &:last-of-type {
          &::after {
            content: '';
            background-color: #3276e8;
            width: 3500px;
            height: 4px;
            position: absolute;
            left: 24px;
            top: 22px;
            z-index: -1;
          }
        }
      }
    }

    .tab-icon-block {
      width: 48px;
      height: 48px;
      background-color: @white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 2px solid #d6d8d9;

      + .tab-icon-block {
        margin-left: 46px;
      }
    }

    .tab-icon-block-active {
      color: @primary-color;
      border: 2px solid @primary-color;
    }
  }

  .info {
    .day-sign {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        color: @main-color;
        position: relative;
        padding-left: 30px;

        > span {
          &:first-of-type {
            display: inline-block;
            width: 35px;
            position: relative;

            &::before {
              content: '';
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: @green;
              position: absolute;
              top: calc(~'50% - 6px');
              left: -20px;
            }

            &::after {
              content: ':';
            }
          }
        }

        + .item {
          margin-top: @margin;
        }
      }
    }
  }

  .medical {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    flex: 1;

    > div {
      width: 100%;
    }

    .medical-block {
      height: 0;
      border: 2px solid #3276e8;
      border-radius: 4px;
      flex: 1;
      display: flex;
      flex-direction: column;
      // overflow-y: auto;
      overflow: hidden;

      .header {
        height: 49px;
        min-height: 49px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0;
        border-bottom: 2px solid #3276e8;
        padding: 0 24px;

        .left {
          display: flex;
          align-items: center;
        }

        .right {
          display: flex;
          align-items: center;

          .title {
            margin: 0 4px;
          }
        }
      }

      .medical-main {
        // height: 100%;
        flex: 1;
        overflow: hidden;
      }
    }

    .medical-extra {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0;
      margin-bottom: 12px;

      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .item {
        cursor: pointer;
        + .item {
          margin-left: 20px;
        }

        .label {
          margin-left: 4px;
        }
      }

      .active-item {
        color: #3276e8;
      }
    }
  }
}

.normal-leftContainer {
  width: 0;
  min-width: 100%;
  max-width: 100%;
}

.medical-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-right: 20px;
  cursor: pointer;
}

.cpoe-medical-template {
  background-color: #fff;
  width: 392px;
  height: calc(~'100vh - 500px');
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;

  .site-tree-search-value {
    color: #f50;
  }
}

@root-entry-name: default;