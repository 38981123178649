@import '~@/style/vars.less';

.@{prefix}-center {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 16px;

  .main-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow: hidden;
    overflow-x: auto;

    padding-top: 24px;
  }

  .main-container-normal {
    > div {
      width: 100% !important;
    }
  }
}

@root-entry-name: default;