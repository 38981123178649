.Inpatient_bed_card_list_content {
  // height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;

  &_item {
    width: 254px;
    height: 216px;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #d6d8d9;
    padding: 0 24px 0;
    margin-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &_empty {
      padding: 16px 24px 24px;
      &_index {
        width: 48px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        color: #343b42;
        font-size: 16px;
        font-weight: 600;
        background: #f1f6ff;
        border-radius: 2px;
      }
      &_content {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #aeb0b3;
        font-size: 14px;
        &_img {
          margin-bottom: 8px;
          box-sizing: border-box;
        }
        &_text {
        }
      }
    }
    &_top {
      height: 150px;
      border-bottom: 1px dashed #aeb0b3;
      box-sizing: border-box;
      box-sizing: border-box;
      padding: 16px 0 12px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &_index {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        &_div {
          width: 4px;
          height: 14px;
          background: #3276e8;
          margin-right: 6px;
        }
        &_text {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #343b42;
        }
        &_num {
          width: 48px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          color: #343b42;
          font-size: 16px;
          font-weight: 600;
          background: #ffb93e;
          border-radius: 2px;
        }
        &_icon {
          display: flex;
        }
      }
      &_info {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 600;
        color: #343b42;
      }
      &_card,
      &_res {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #343b42;
      }
    }
    &_bottom {
      padding-top: 10px;
      box-sizing: border-box;
      flex-shrink: 0;
      box-sizing: border-box;
      height: 66px;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      &_time {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        color: #5d6268;
      }
    }
  }
}

@root-entry-name: default;