.Inpatient_bed_card {
  display: flex;
  flex-direction: column;
  // background-color: #fff;
  color: red;

  background-color: #fff;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 24px;

  &_search {
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 1414px;
  }
  &_list {
    // flex: 1;
    width: 1414px;
    max-height: 1006px;
    overflow-y: auto;
    height: calc(~'100vh - 400px');
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
}

@root-entry-name: default;