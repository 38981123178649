.previous_history{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    overflow-y: scroll;
    &_add{
        margin-right: 34px;
        box-sizing: border-box;
        width: 44px;
        height: 20px;
        border-radius: 2px;
        opacity: 1;
        border: 1px solid #3276E8;
        font-size: 14px;
        text-align: center;
        line-height: 18px;
        font-weight: 400;
        color: #3276E8;
        cursor: pointer;
    }
    &_option{
        display: flex;
        color: #3276E8;
        cursor: pointer;
        &_item{
            margin-right: 12px;
            box-sizing: border-box;
        }
    }
    &_list{
            margin-bottom: 30px;
            box-sizing: border-box;
              &_tabel{
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  width: 100%;
                      .ant-table-thead .ant-table-cell {
                          background-color: #DBEDFF !important;
                          font-size: 16px;
                          font-weight: 600;
                          &::before {
                            background-color: transparent !important;
                          }
                  }
                  td.ant-table-cell {
                    background-color: transparent;
                  }
          
                  .ant-pro-card {
                    background-color: transparent;
                  }
          
                  .ant-table {
                    background-color: transparent !important;
                  }
          } 
    }
    &_diseas_type{
            width: 48px;
            padding: 0 8px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            &_anxious{
                color: #F54141;
                background-color: rgba(243, 88, 89, 0.1);
            }
            &_slow{
                color: #FF7D45;
                background-color: rgba(255, 125, 69, .1);
            }

    }
    &_allergys_type{
            width: 48px;
            padding: 0 8px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            &_food{
               background-color: #fff7ea;
               color: #ffc188;
            }
            &_medicine{
               color: #a485ff;
                background-color: #f1ecff;
            }
    }
}

@root-entry-name: default;