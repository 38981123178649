.Inpatient_bed_card_search_content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
        font-weight: 600;
        font-size: 16px;
    }
    &_text{
        margin: 0 16px 0 60px;
        font-size: 16px;
        font-weight: 400;
        color: #343B42;
    }
    &_change{
        display: flex;
        justify-content: space-between;
        width: 78px;
        height: 24px;
        font-size: 12px;
        line-height: 24px;
        background: #3276E8;
        border-radius: 2px;
        color: #FFFFFF;
        padding: 0 6px;
        cursor: pointer;
    }
}
@root-entry-name: default;