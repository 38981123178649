.cpoe-empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  user-select: none;

  .center {
    width: 414px;
    height: 368px;
    position: relative;
    background-size: cover !important;
  }

  .desc {
    font-size: 20px;
    color: #3276e8;
    position: absolute;
    bottom: 90px;
    width: 100%;
    text-align: center;
  }
}

@root-entry-name: default;