.checked_list{
    width: 336px;
    margin-right: 24px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
&_header{
    position:sticky;
    top: 0;
    margin-bottom: 18px;
    &_time{
        display: flex;
        margin-bottom: 12px;
        box-sizing: border-box;
    }
    &_filter{
        &_check{
            font-size: 12px;
            margin-bottom: 12px;
            box-sizing: border-box;
        }
        &_button{
            &_item{
                border: none !important;
                width: 78px;
                height: 28px;
                line-height: 28px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
                padding: 0 15px;
                margin-bottom: 6px !important;
                box-sizing: border-box;
            }
        }
    }
}
&_content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
&_item{
    word-break: break-all;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    min-height: 92px;
    border-radius: 4px;
    margin-bottom: 12px;
    background: rgba(255,255,255,0.5);
    border: 2px solid rgba(255,255,255,0);
    cursor: pointer;
    &_time{
        display: flex;
        align-items: center;
        font-size: 16px;
        &_line{
            height: 1px;
            width: 26px;
            margin: 0 4px;
            background-color: #3276E8;
        }

    }
    &_classify_name{
        font-size: 14px;
        font-weight: 600;
        color: #343B42;
    }
    &_doctor_name{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        color: #343B42;
        &_label{
            font-weight: normal;
            color: #85898D;
        }
        &_text{
            width: 64px;
            height: 18px;
            font-size: 12px;
            text-align: center;
            line-height: 16px;
            color: #50BC35;
            background: rgba(80,188,53,0.2);
            border-radius: 2px 2px 2px 2px;
            opacity: 1;
            border: 1px solid rgba(80,188,53,0.5);
        }
    }
}
}
}
.checked_list_content_item_active{
    border: 2px solid #3276E8 ;
}
@root-entry-name: default;