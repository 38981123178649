@import '~antd/dist/antd.less';
@import '~@vs/vs-design-tokens/index.less';

/*
 类名变量，全局的 less 需文件导入用到
 */
@prefix: ~'aspirin-cpoe';
@layout: ~'@{prefix}-layout';
@header: ~'@{prefix}-layout-header';
@sider: ~'@{prefix}-layout-sider';
@menu: ~'@{prefix}-layout-menu';
@right: ~'@{prefix}-layout-right';
@footer: ~'@{prefix}-layout-footer';

/**
 * 颜色变量
 */
@primary-color: #3276e8;
@primary-color-1: #69a1ff;
@primary-color-2: #bad5ff;
@green: #50bc35;
@red: #ff7e7e;
@orange: #ffa57e;
@white: #fff;

@title-color: #343b42;
@main-color: #5d6268;
@grey-1: #85898d;
@grey-2: #aeb0b3;
@grey-3: #d6d8d9;

/**
 * 边距变量
 */
@padding: 16px;
@margin: 16px;
@margin-1: 4px;
@margin-2: 8px;
@margin-3: 12px;
@margin-4: 16px;
@margin-5: 20px;
@padding-1: 4px;
@padding-2: 8px;
@padding-3: 12px;
@padding-4: 16px;
@padding-5: 20px;

/**
 * 字号变量
 */
@font-size: 16px;
@font-size-12: 12px;
@font-size-14: 14px;
@font-size-18: 18px;
@font-size-20: 20px;

/**
 * 其他变量
 */
@border-radius: 4px;
@font-weight-bold: 500;
@font-weight-normal: 400;
@font-weight-thin: 300;
@border-width: 1px;

// 原生CSS变量，可以在灵活的在JS中使用，比less灵活一点
:root {
  --cpoe-padding: @padding;
}
