@import '~@/style/vars.less';

.@{prefix}-index-center-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;

  .oper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-right: 60px;
    padding-left: 24px;
    border-right: 1px solid #aeb0b3;

    .first {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .ant-btn {
        height: 42px;
        border: 1px solid #3276e8;
        color: #3276e8;
        font-size: 18px;
        border-radius: 4px;

        + .ant-btn {
          margin-left: 24px;
        }
      }
    }

    .second {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #3276e8;
      margin-top: 16px;
      border-radius: 4px;
      height: 42px;
      background-color: #fff;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #343b42;
        padding-left: 10px;
        padding-right: 14px;
        flex: 1;
        border-right: 1px solid #3276e8;
        max-width: 170px;
        cursor: pointer;

        .vp-icon {
          margin-left: 4px;
        }

        .label {
          font-size: 18px;
          margin-left: 4px;

          &::after {
            content: ':';
            margin: 0 6px;
            position: relative;
            top: -1px;
          }
        }
        .value {
          font-size: 22px;
        }
      }

      .input {
        flex: 1;

        .ant-input {
          border-width: 0;

          &:hover {
            border-color: transparent;
          }

          &:focus {
            box-shadow: unset;
          }
        }
      }

      .scan,
      .list {
        width: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-left: 1px solid #3276e8;
        flex: 0;
        width: 72px;
        min-width: 72px;
        cursor: pointer;
      }

      .scan {
        color: #3276e8;
      }

      .list {
        background-color: #3276e8;
        color: #fff;
      }
    }
  }

  .oper-in {
    width: 216px;
    padding-right: 24px !important;
    border-right: 1px solid #aeb0b3;
    box-sizing: content-box;

    .first {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        height: 42px;

        &:first-of-type {
          width: 116px;
        }

        &:last-of-type {
          width: 88px;
        }
      }
    }

    .second-in {
      display: flex;
      margin-top: 16px;

      .ant-btn {
        height: 42px;
        width: 216px;
      }
    }
  }

  .number {
    display: flex;
    margin-left: 34px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    min-width: 64px;
    background-color: #fff;
    border-radius: 50%;
    color: #e4e4e4;

    // > .label {
    //   white-space: nowrap;
    //   color: #343b42;
    //   font-size: 20px;
    //   font-weight: 500;
    //   margin-bottom: -4px;
    // }

    // > .value {
    //   font-size: 20px;
    //   color: #3276e8;
    // }
  }

  .main {
    color: @title-color;
    margin-right: 70px;
    margin-left: 34px;

    .first,
    .second {
      white-space: nowrap;
    }
    .name {
      font-size: 30px;
      font-weight: @font-weight-bold;
    }

    .gender {
      margin: 0 @margin-2;
    }

    .age {
      font-size: @font-size-14;
    }

    .label {
      font-weight: @font-weight-normal;
      font-size: @font-size;
      &::after {
        content: ':';
      }
    }

    .value {
      font-size: @font-size-20;
      margin-left: @margin-3;
    }
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -30px;
    margin-right: 30px;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;

    .info-item {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-width: 265px;

      .info-bar {
        width: 4px;
        height: 100%;
        background-color: red;
        position: absolute;
      }

      .info-content {
        padding-left: @padding-4;

        .item-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }

        .label {
          display: inline-block;
          padding: 0 12px 0 0;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;

          > span {
            flex: 1;

            &:last-of-type {
              text-align: center;
            }
          }

          &::after {
            content: ':';
          }
        }

        .value {
          white-space: nowrap;
          font-size: @font-size-20;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      + .info-item {
        margin-left: 60px;
      }
    }
  }
}

.cpoe-patient-list {
  background-color: #fff;
  width: 500px;
  height: calc(~'100vh - 500px');
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  .ant-tabs-nav-wrap {
    padding-left: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    .header {
      padding: 0 24px;
      margin-top: 16px;
    }

    .main {
      margin-top: 16px;
      flex: 1;
      padding: 0 24px;
    }

    .footer {
      height: 47px;
      min-height: 47px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #d9d9d9;
      width: 100%;
      padding-right: 24px;

      .ant-btn {
        + .ant-btn {
          margin-left: 16px;
        }
      }
    }
  }
}

@root-entry-name: default;